<template>
  <base-page :title="$t('companyInfo.title')" @functionMethod="functionMethod">
    <detail-list :formData="formData" :config="config">
      <!-- 所属行业 -->
      <template v-slot:industry="{ data }">
        {{
          data.industry
            ? $util.getTreeNames(data.industry, 'value')
            : data.customIndustry
        }}
      </template>
      <!-- 联系地址 -->
      <template v-slot:address="{ data }">
        {{
          $util.getTreeNames(data.address, 'value') +
          (data.address ? '/' : '') +
          data.addressDetail
        }}
      </template>

      <!-- 发票信息标题 -->
      <template v-slot:invoiceTitle>
        <h1
          style="
            font-size: 14px;
            font-weight: bold;
            line-height: 40px;
            color: #999;
          "
        >
          {{ $t('companyManage.invoice') }}
        </h1>
      </template>
      <template v-slot:companyInviteCode>
        <div style="display: flex;flex-direction: row;justify-content: space-between">
          <div>{{inviteCode}}</div>
          <el-button @click="getCompanyInviteCode">{{inviteCode? $t('companyManage.updateInvite'):$t('companyManage.createInvite')}}</el-button>
        </div>
      </template>
    </detail-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <invoice v-if="showInvoice" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'
import Invoice from './../../companyManage/invoice'
import { importFile } from '@/utils/ajax'
import {createCompanyInviteCode, findCompanyInviteCode} from "@/api";

export default {
  components: {
    Detail,
    Invoice,
  },
  data() {
    return {
      tableRow: null,
      // 详情
      formData: null,
      resourceFeeModeEnumObj: this.$util.listToObj(
        this.$dict.resourceFeeModeEnum()
      ),
      config: [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
          span: 12,
        },
        // lang:企业所属行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          span: 12,
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          span: 12,
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
          span: 12,
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
          span: 12,
        },
        // lang:联系邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
          span: 12,
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          callback: (row) => {
            return this.$util.format(row.createTime, 'yyyy-MM-dd hh:mm:ss')
          },
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
        // lang:企业邀请码
        {
          tag: 'slot-content',
          label: this.$t('companyManage.companyInviteCode'),
          prop: 'companyInviteCode',
        },
        // 标题：发票信息
        {
          tag: 'slot',
          prop: 'invoiceTitle',
        },
        // lang:开票企业名称
        {
          label: this.$t('companyManage.companyNameForInvoice'),
          prop: 'companyNameForInvoice',
          span: 12,
        },
        // lang:统一社会信用代码
        {
          label: this.$t('companyManage.creditCodeForInvoice'),
          prop: 'creditCodeForInvoice',
          span: 12,
        },
        // lang:开户行名称
        {
          label: this.$t('companyManage.openAccountBankNameForInvoice'),
          prop: 'openAccountBankNameForInvoice',
          span: 12,
        },
        // lang:开户行账号
        {
          label: this.$t('companyManage.openAccountBankAccountForInvoice'),
          prop: 'openAccountBankAccountForInvoice',
          span: 12,
        },
        // lang:开户电话
        {
          label: this.$t('companyManage.openAccountTelNumForInvoice'),
          prop: 'openAccountTelNumForInvoice',
          span: 12,
        },
        // lang:开户地址
        {
          label: this.$t(
            'companyManage.openAccountBankAddressAddressForInvoice'
          ),
          prop: 'openAccountBankAddressAddressForInvoice',
          span: 12,
        },
      ],
      showInvoice: false,
      showDetail: false,
      inviteCode: '',
    }
  },
  mounted() {
    this.findMyCompany()
    this.findCompanyInviteCode()
  },
  methods: {
    findCompanyInviteCode() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findCompanyInviteCode,
        data: {
        },
      }).then(res => {
        console.log(res)
        this.inviteCode = res
      })
    },
    getCompanyInviteCode() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.createCompanyInviteCode,
        data: {
        },
      }).then(res => {
        console.log(res)
        this.inviteCode = res
      })
    },
    // 刷新页面
    closePage(method) {
      this.showInvoice = false
      this.showDetail = false
      if (method !== 'cancel') {
        this.findMyCompany()
      }
    },
    // 获取可用计费模式显示
    getFeeModeStr(feeModeStr) {

      // const { level } = this.formData
      // if (level !== 'ONE' && level !== 'TWO') return
      // if (level === 'ONE') feeModeStr = 'METERING,ACCOUNT_SUBSCRIPTIONS'

      // feeModeStr = feeModeStr.split(',')
      // feeModeStr = feeModeStr.map((item) => this.resourceFeeModeEnumObj[item])
      // return feeModeStr.join(',')
    },
    // 查询企业信息
    findMyCompany() {
      this.$ajax({
        url: this.$api.findMyCompany,
        data: {
          needFullAddress: 'YES',
          needFullIndustry: 'YES',
          productType: this.$util.getLocalStorage('productType')
        },
      }).then((data) => {
        this.tableRow = this.$util.copyData(data)
        const { level } = data
        // 顶级企业和Tmva显示可用计费模式
        if (level === 'ONE' || level === 'TWO') {
          this.config[1].hidden = false
        } else {
          // OEM和代理商和用户企业显示付费模式
          this.config[2].hidden = false
        }
        Object.assign(data, data.invoice)
        this.formData = data
      })
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      if (item.method === 'updateCompany') {
        this.showDetail = true
        return
      }
      if (item.method === 'export') {
        importFile({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyExportCompanyCode,
          name: this.$util.format(new Date(), 'yyyy-MM-dd-hh-mm-ss'),
          type: '.zip',
          data: {
            companyIds: [this.$store.getters.company.id],
          },
        })
        return
      }
      this.showInvoice = true
    },
  },
}
</script>
